<template>
  <div>
    <div
      v-for="(item, index) in brandContents"
      :key="index"
      @click="goDetail(item)"
      class="contentWrap"
    >
      <img
        class="img"
        :src="newBaseurl + item.parPh"
        alt=""
        @error="handleNoImg"
      />
      <div class="content-detail">
        <h3 class="title">{{ item.parTitle }}</h3>
        <div class="date">{{ item.pubDtm }}</div>
        <p class="text">{{ item.parDesc }}</p>
      </div>
    </div>
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
      @prev-click="handlePageChange"
      @next-click="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brandContents: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.pageIndex = parseInt(this.$route.params.page) || 1;
  },
  mounted() {
    this.getBrand();
  },
  watch: {
    "$route.params.page"(val) {
      this.pageIndex = val;
      this.getBrand();
    },
  },
  methods: {
    getBrand() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafParty/tableList.ajax",
        data: {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          _entityName: "org.aweto.laf.entity.LafParty",
          condition: "parClassify:S_IN2||",
          orderCondition: "desc",
          orderField: "pubDtm",
        },
      })
        .then((res) => {
          this.brandContents = res.rows;
          this.total = res.totalRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 详情
    goDetail(data) {
      // const { row_pkValue } = data;
      const row_pkValue = data.row_pkValue;
      window.open(
        `./#/partyBuild/partyDetail?row_pkValue=${row_pkValue}`,
        "_blank"
      );
      // this.$router.push({
      //   path: "/partyBuild/partyDetail",
      //   query: {
      //     row_pkValue: row_pkValue,
      //   },
      // });
    },

    handlePageChange(index) {
      this.$router.push({
        name: "partyBrand",
        params: {
          page: index,
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.contentWrap {
  padding: 20px;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  height: 200px;
  &:hover {
    background: #f8f8f8;
  }

  .img {
    display: block;
    width: 180px;
    height: 150px;
    float: left;
    object-fit: cover;
  }
  .content-detail {
    margin-left: 180px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .title {
    font-size: 20px;
    margin-bottom: 12px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px;
    line-height: 30px;
  }
  .date {
    color: #999;
    margin-bottom: 25px;
    font-size: 14px;
  }
  .text {
    font-size: 16px;
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.link {
  height: 2px;
  background: #e5e5e5;
}
.fenYe {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
</style>